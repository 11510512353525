<template>
  <div>
    <b-modal
        id="modal-unsubscribe-user-from-report"
        ref="modal-unsubscribe-user-from-report"
        footer-class="flex-nowrap"
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.deleteUser.title') }}</h3>
      </template>

      <template #default>
        <div class="delete-icon text-center">
          <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
          <div v-if="userToBeDeleted">
            <div v-if="userToBeDeleted.name">{{ userToBeDeleted.name }}</div>
            <div v-if="userToBeDeleted.email">{{ userToBeDeleted.email }}</div>
          </div>
          <div class="mt-2 mb-4">{{ $t('modals.deleteUser.message') }}</div>
        </div>
      </template>

      <template #modal-footer>
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="cancel"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            variant="primary"
            class="p-3"
            block
            @click="deleteUser"
        >
          {{ $t('modals.deleteUser.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalUnsubscribeUserFromReport',
  props: {
    userToBeDeleted: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$refs['modal-unsubscribe-user-from-report'].show();
  },
  methods: {
    async deleteUser() {
      this.$emit('deleteUser');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>

</style>
